import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatRippleModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatListModule } from '@angular/material/list';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSortModule } from '@angular/material/sort';

export const MAT_MODULES = [
	MatIconModule,
	MatToolbarModule,
	MatRippleModule,
	MatDatepickerModule,
	MatSnackBarModule,
	MatFormFieldModule,
	MatButtonModule,
	MatInputModule,
	MatProgressSpinnerModule,
	MatMenuModule,
	MatBadgeModule,
	MatCardModule,
	MatTableModule,
	MatProgressBarModule,
	MatPaginatorModule,
	MatDialogModule,
	MatTabsModule,
	MatNativeDateModule,
	MatMomentDateModule,
	MatRadioModule,
	MatCheckboxModule,
	MatSelectModule,
	MatDividerModule,
	MatAutocompleteModule,
	MatChipsModule,
	MatSliderModule,
	MatTooltipModule,
	MatExpansionModule,
	MatBottomSheetModule,
	MatListModule,
	MatButtonToggleModule,
	MatSortModule
];

export const CUSTOM_DATE_FORMATS = {
	parse: {
		dateInput: 'YYYY-MM-DD',
	},
	display: {
		dateInput: 'DD.MM.YYYY',
		monthYearLabel: 'MMMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

@NgModule({
	imports: [
		CommonModule,
		...MAT_MODULES
	],
	exports: [
		...MAT_MODULES
	],
	providers: [
		{ provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
		{ provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
		{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }
	]
})
export class MaterialModule { }
