<div class="bg-[#F3F3F3] p-8">

    <div *ngIf="compareDates(data.feedbackDate, data.assessment?.timestamp)" class="flex items-center p-4 mb-4 text-sm border border-dc-turquoise-300 rounded-lg bg-dc-turquoise-50" role="alert">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
            <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
        </svg>
        <span class="sr-only">Info</span>
        <div class="ml-1">
            <span class="font-medium">Achtung!</span> Es wurden Änderungen an diesem Ergebnis <span class="font-medium">nach der Rückmeldung</span> vorgenommen.
        </div>
    </div>

    <h2 class="!inline-flex !w-full !m-0 !p-0 items-center" mat-dialog-title>
        Laborergebnis:
        {{getAlarmLevel(data.assessment) | alert}} <span [ngClass]="'ml-1 circle circle-' + (getAlarmLevel(data.assessment) | lowercase)"></span>
        <span class="flex-1"></span>
        <span>{{data.sampleNumber}}</span>
    </h2>
    <small class="text-sm text-[#777] flex justify-start mb-4 -mt-[10px]">
        <span class="text-sm material-symbols-outlined mr-1">event_available</span>{{data.assessment.timestamp | date: 'dd.MM.YYYY'}}
        <span class="text-sm material-symbols-outlined ml-2 mr-1">schedule</span>{{data.assessment.timestamp | date: 'HH:mm:ss'}} Uhr
    </small>

    <ul *ngIf="data.assessment.highlyDosed || data.assessment.misdeclared || data.assessment.contaminated" 
        class="m-0 p-0 inline-flex list-none mb-4">
        <li *ngIf="data.assessment.misdeclared" 
        class="bg-dc-blue-400 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded">
            falsch deklariert
        </li>
        <li *ngIf="data.assessment.highlyDosed" 
            class="bg-dc-blue-400 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded">
            hochdosiert
        </li>
        <li *ngIf="data.assessment.contaminated" 
            class="bg-dc-blue-400 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded">
            verunreinigt
        </li>
    </ul>

    <mat-dialog-content class="bg-[#FFFFFF] !max-h-[40vh] !mb-4 !text-[#000000]">
        <span [innerHTML]="sanitizedDescription"></span>
    </mat-dialog-content>

    <div class="mb-4 w-full">
        <img *ngFor="let sequential of [1, 2]"
             [src]="getImageUrl(sequential)" 
             (error)="handleImageError($event)" 
             (click)="openLightbox(sequential)"
             alt="Bild {{sequential}}" 
             class="object-cover w-16 h-16 hover:cursor-zoom-in mr-4" />
    </div>

    <table mat-table *ngIf="dataSourceAnalysis.data.length" [dataSource]="dataSourceAnalysis" class="mat-elevation-z0 mb-4">
        <!-- Weight Column -->
        <ng-container matColumnDef="weight">
            <th mat-header-cell *matHeaderCellDef> Gewicht </th>
            <td mat-cell *matCellDef="let element"> 
                <span *ngIf="element.weight">{{element.weight | numberFormat}} mg</span>
                <span *ngIf="!element.weight">k.A.</span>
            </td>
        </ng-container>
        <!-- Height Column -->
        <ng-container matColumnDef="height">
            <th mat-header-cell *matHeaderCellDef> Höhe </th>
            <td mat-cell *matCellDef="let element"> 
                <span *ngIf="element.height">{{element.height | numberFormat}} mm</span>
                <span *ngIf="!element.height">k.A.</span>
            </td>
        </ng-container>
        <!-- Width Column -->
        <ng-container matColumnDef="width">
            <th mat-header-cell *matHeaderCellDef> Breite </th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.width">{{element.width | numberFormat}} mm</span>
                <span *ngIf="!element.width">k.A.</span>
            </td>
        </ng-container>
        <!-- Depth Column -->
        <ng-container matColumnDef="depth">
            <th mat-header-cell *matHeaderCellDef> Tiefe </th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.depth">{{element.depth | numberFormat}} mm</span>
                <span *ngIf="!element.depth">k.A.</span>
            </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumnsAnalysis"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsAnalysis;"></tr>
    </table>

    <table mat-table *ngIf="dataSourceSubstances.data.length" [dataSource]="dataSourceSubstances" class="mat-elevation-z0">
        <!-- Number Column -->
        <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef> Substanz </th>
            <td mat-cell *matCellDef="let element; let index = index"> {{index + 1}} </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
        <!-- Amount Column -->
        <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef> Menge </th>
            <td mat-cell *matCellDef="let element"> {{element.amount | numberFormat}} {{element.unit}} </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumnsSubstances"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsSubstances;"></tr>
    </table>

</div>

<mat-dialog-actions align="start">
    <button mat-button mat-dialog-close>Abbrechen</button>
    <span class="flex-1"></span>
    <button mat-flat-button color="primary" cdkFocusInitial (click)="feedbackGiven()" [disabled]="data.sampleStatus === 'REPORTED'">
        <mat-icon>check</mat-icon> Rückmeldung
    </button>
</mat-dialog-actions>