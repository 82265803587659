import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from '@core/services/api.service';
import { Role } from '@shared/enums/role.enum';
import { User } from '@shared/models/user.interface';

@Component({
	selector: 'app-user-dialog',
	templateUrl: './user-dialog.component.html',
	styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent implements OnInit {

	/** Flag indicating whether the component is in add mode. */
	isAddMode: boolean = false;

	/** The form group used to create and update users. */
	userForm: FormGroup;

	userRoleOptions: any;

	salutationOptions: any = [
		// {name: 'Keine Angabe', value: null},
		{name: 'Herr', value: 'MR'},
		{name: 'Frau', value: 'MS'},
		{name: 'Divers', value: 'DIVERSE'},
	]

	organizationOptions: any = [
		{name: 'Vista', value: 'VISTA'},
		{name: 'Schwulenberatung', value: 'SCHWULENBERATUNG'},
		{name: 'Fixpunkt', value: 'FIXPUNKT'}
	]

	user: User;

	/** Flag indicating HTTP request is busy */
	isLoading: boolean = false;

	constructor(
		private fb: FormBuilder,
		private apiService: ApiService,
		private snackBar: MatSnackBar,
		private dialogRef: MatDialogRef<UserDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: User
	) { }

	ngOnInit(): void {

		// Get the list of roles enum and convert it to an array of objects
		this.userRoleOptions = Object.keys(Role).map((key: any) => {
			const value = Role[key as keyof typeof Role];
			return { name: value, value: key };
		});

		// Set isAddMode to true if the dialog was opened without an ID
		this.isAddMode = !this.data;
		
		this.userForm = this.fb.group({
			id: new FormControl(null),
			// displayName: new FormControl(''),
			emailAddress: new FormControl('', Validators.required),
			firstname: new FormControl(''),
			lastname: new FormControl(''),
			organisation: new FormControl(null, Validators.required),
			role: new FormControl('', Validators.required),
			salutation: new FormControl(null),
			userStatus: new FormControl('ACTIVE'),
			username: new FormControl('', Validators.required),
			password: new FormControl('', [Validators.required, Validators.minLength(12)])
		});
		
		if ( !this.isAddMode ) {
			this.user = this.data;
			this.userForm.get('password')?.setValidators(Validators.minLength(12));
			this.userForm.patchValue(this.data)
		}
	}

	/**
	 * Submits the user form.
	 *
	 * If the form is valid, either creates or updates the user.
	 *
	 * @since 1.0.0
	 * @return void
	 */
	onSubmit(): void {
		if ( this.userForm.valid ) {
			if (this.isAddMode) {
				this.createUser();
			} else {
				this.updateUser();
			}
		}
	}

	/**
	 * Updates the user with the specified ID.
	 * @since 1.0.0
	 * @return void
	 */
	private updateUser(): void {
		this.apiService.updateUser(this.userForm.get('id')?.value, this.userForm.value).subscribe(() => {
			this.snackBar.open('Benutzer*in erfolgreich geändert.', 'Schließen', {panelClass: 'success-snack'});
			// Close the dialog and pass the 'success' data back to the caller
			this.dialogRef.close({data: 'success'});
		});
	}

	/**
	 * Creates a new user.
	 * @since 1.0.0
	 * @returns void
	 */
	private createUser(): void {
		this.isLoading = true;
		this.apiService.createUser(this.userForm.value).subscribe({
			next: () => {
				this.snackBar.open('Benutzer*in erfolgreich angelegt.', 'Schließen', {panelClass: 'success-snack'})
				// Close the dialog and pass the 'success' data back to the caller
				this.dialogRef.close({data: 'success'});
			},
			error: () => {
				this.snackBar.open('Benutzer*in konnte nicht angelegt werden.', 'Schließen', {panelClass: 'error-snack'});
			}, 
			complete: () => {
				this.isLoading = false;
			}
		});
	}


	/**
	 * Changes user status
	 */
	updateUserStatus(status: string): void {
		this.user.userStatus = status;
		this.isLoading = true;
		this.apiService.updateUser(this.user.id, this.user).subscribe({
			next: () => {
				this.snackBar.open('Benutzer*in erfolgreich geändert.', 'Schließen', {panelClass: 'success-snack'});
				// Close the dialog and pass the 'success' data back to the caller
				this.dialogRef.close({data: 'success'});
			},
			error: () => {
				this.snackBar.open('Benutzer*in konnte nicht geändert werden.', 'Schließen', {panelClass: 'error-snack'});
			}, 
			complete: () => {
				this.isLoading = false;
			}
		});
	}
}
