<app-toolbar></app-toolbar>

<div class="flex flex-col h-auto p-5">

	<div class="flex flex-row gap-4">

		<div class="w-1/4">

			<mat-card>
				<mat-card-header class="flex flex-col">
					<mat-card-title class="!inline-flex !items-center">
						<span class="material-symbols-outlined mr-2">lab_research</span>Probencode
					</mat-card-title>
				</mat-card-header>
				<mat-card-content class="!inline-flex flex-col mt-4">
					<app-sample-number-search-form (result)="sampleSearchResult($event)" (valid)="isSampleValid($event)">
						<mat-icon class="!text-dc-blue-500 !mr-4" *ngIf="isValidSampleNumber">verified</mat-icon>
					</app-sample-number-search-form>
				</mat-card-content>
			</mat-card>

			<mat-card class="mt-4">
				<mat-card-header class="flex flex-col">
					<mat-card-title class="!inline-flex !items-center">
						<span class="material-symbols-outlined mr-2">person_search</span>Klient*in UID
					</mat-card-title>
				</mat-card-header>
				<mat-card-content class="!inline-flex flex-col mt-4">
					<app-client-search-form (searchResults)="onSearchResultsChange($event)"></app-client-search-form>
					<button type="button" mat-flat-button color="primary" class="mt-4" (click)="openClientDialog()">
						UID rekonstruieren
					</button>
				</mat-card-content>
			</mat-card>

		</div>

		<div class="w-full">

			<mat-card>
				<mat-card-header class="mb-5">
					<mat-card-title class="!inline-flex !items-center">
						<mat-icon fontSet="material-icons-outlined" class="mr-2">mark_email_read</mat-icon>
						Rückmeldungen
					</mat-card-title>
				</mat-card-header>

				<mat-card-content id="resultBySampleNumber" class="!flex !flex-col">

					<mat-card *ngIf="isValidSampleNumber || isValidClientUid" class="folder w-[25rem] p-4 mb-8 !bg-[#F2F2F2]">

						<h3 class="inline-flex items-center">
							Klient*in: {{currentClient.clientUid}}
							<app-copy-to-clipboard-button [textToCopy]="currentClient.clientUid"></app-copy-to-clipboard-button>
						</h3>

						<table class="table-fixed w-full">
							<tr>
								<td>Geschlecht</td>
								<td>{{currentClient.gender | gender}}</td>
							</tr>
							<tr>
								<td>Alter</td>
								<td>{{currentClient.age}}</td>
							</tr>
							<tr>
								<td>Wohnhort (PLZ)</td>
								<td>{{currentClient.postalCode}}</td>
							</tr>
							<tr>
								<td>Staatsangehörigkeit(en)</td>
								<td>{{currentClient.nationality | nationality}}
									{{currentClient.nationality2 ? ' / ' + (currentClient.nationality2 | nationality) : ''}}
								</td>
							</tr>
							<tr>
								<td>Erste Beratung</td>
								<td>{{currentClient.firstConsultationDate | date: 'dd.MM.YYYY'}}</td>
							</tr>
							<tr>
								<td>Letzte Beratung</td>
								<td>{{currentClient.lastConsultationDate | date: 'dd.MM.YYYY'}}</td>
							</tr>
						</table>

					</mat-card>

					<ng-container *ngIf="isValidSampleNumber || isValidClientUid" >

						<mat-form-field>
							<mat-label>Filter</mat-label>
							<input matInput (keyup)="applyFilter($event)" #input>
						</mat-form-field>

						<table mat-table [dataSource]="dataSource" class="mat-elevation-z0" matSort>

							<!-- Results Column -->
							<ng-container matColumnDef="result">
								<th mat-header-cell *matHeaderCellDef> Ergebnis </th>
								<td mat-cell *matCellDef="let element">
									<button mat-icon-button 
											color="primary" 
											[disabled]="['PENDING', 'INVALID'].includes(element.sampleStatus)" 
											(click)="openResultDialog(element)">
										<mat-icon *ngIf="element.sampleStatus !== 'INVALID'">folder_open</mat-icon>
										<span *ngIf="element.sampleStatus === 'INVALID'" class="material-symbols-outlined">folder_off</span>
									</button>
								</td>
							</ng-container>
		
							<!-- Sample Status Column -->
							<ng-container matColumnDef="sampleStatus">
								<th mat-header-cell *matHeaderCellDef mat-sort-header> Status der Probe </th>
								<td mat-cell *matCellDef="let element"> 
									<span [ngClass]="{
										'reported': element.sampleStatus === 'REPORTED'
									}">{{element.sampleStatus | sampleStatus}} 
									</span>
								</td>
							</ng-container>
		
							<!-- Sample Number Column -->
							<ng-container matColumnDef="sampleNumber">
								<th mat-header-cell *matHeaderCellDef> Probencode </th>
								<td mat-cell *matCellDef="let element"> 
									{{element.sampleNumber}} 
									<app-copy-to-clipboard-button [textToCopy]="element.sampleNumber"></app-copy-to-clipboard-button>
								</td>
							</ng-container>
		
							<!-- Sample Name Column -->
							<ng-container matColumnDef="name">
								<th mat-header-cell *matHeaderCellDef> Erworben als </th>
								<td mat-cell *matCellDef="let element"> {{element.name}} </td>
							</ng-container>
		
							<!-- Submission Date Column -->
							<ng-container matColumnDef="submissionDate">
								<th mat-header-cell *matHeaderCellDef mat-sort-header> Datum der Abgabe </th>
								<td mat-cell *matCellDef="let element"> {{element.submissionDate | date: 'dd.MM.YYYY'}} </td>
							</ng-container>

							<!-- Alert Column -->
							<ng-container matColumnDef="riskAssessment">
								<th mat-header-cell *matHeaderCellDef> Risikobewertung </th>
								<td mat-cell *matCellDef="let element"> 
									<span [ngClass]="'circle circle-' + (getAlarmLevel(element.result) | lowercase)"></span>
									{{getAlarmLevel(element.result) | alert}}
								</td>
							</ng-container>
							
							<!-- Set Sample To Invalid Column
							<ng-container matColumnDef="setSampleToInvalid">
								<th mat-header-cell *matHeaderCellDef> Probe verloren/nicht analysierbar </th>
								<td mat-cell *matCellDef="let element"> 
									<button mat-flat-button color="warn" class="uppercase" (click)="updateSampleStatus(element, 'INVALID')" [disabled]="['INVALID', 'REPORTED'].includes(element.sampleStatus)">
										<mat-icon>close</mat-icon> Invalid
									</button>
								</td>
							</ng-container>
							-->

							<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
							<tr mat-row *matRowDef="let row; columns: displayedColumns;" class="hover:!bg-[#F1F1F1]"></tr>

							<!-- Row shown when there is no matching data. -->
							<tr class="mat-row" *matNoDataRow>
								<td class="mat-cell text-center p-6" colspan="6">Es konnten keine Proben gefunden werden.</td>
							</tr>
		
						</table>

					</ng-container>
	
				</mat-card-content>

				<p class="p-4 text-center italic" *ngIf="!isValidSampleNumber && !isValidClientUid">
					Geben Sie zunächst einen Probencode oder eine Klient*in UID ein.
				</p>

			</mat-card>

		</div>

	</div>

</div>