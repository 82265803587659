<app-toolbar></app-toolbar>

<div class="flex flex-col h-auto p-5">

	<div class="flex flex-row gap-4">

		<div class="w-1/4">

			<mat-card>
				<mat-card-header class="flex flex-col">
					<mat-card-title class="!inline-flex !items-center">
						<span class="material-symbols-outlined mr-2">search</span> Filter
					</mat-card-title>
				</mat-card-header>
				<mat-card-content class="!inline-flex flex-col mt-4">
					<mat-form-field subscriptSizing="dynamic">
                        <mat-label>Durchsuchen</mat-label>
                        <input matInput (keyup)="applyFilter($event)" #input>
                    </mat-form-field>
				</mat-card-content>
			</mat-card>

		</div>

		<div class="w-full">

			<mat-card>
				
				<mat-card-header class="mb-5">
					<mat-card-title class="!inline-flex !items-center">
						<mat-icon fontSet="material-icons-outlined" class="mr-2">mark_email_read</mat-icon>
						Offene Rückmeldungen aller Klient*innen
					</mat-card-title>
				</mat-card-header>

				<mat-card-content class="!flex !flex-col">

					<table mat-table [dataSource]="dataSource" class="mat-elevation-z0" matSort>

						<!-- Results Column -->
						<ng-container matColumnDef="result">
							<th mat-header-cell *matHeaderCellDef> Ergebnis </th>
							<td mat-cell *matCellDef="let element">
								<button mat-icon-button 
										color="primary" 
										[disabled]="['PENDING', 'INVALID'].includes(element.sampleStatus)" 
										(click)="openResultDialog(element)">
									<mat-icon *ngIf="element.sampleStatus !== 'INVALID'">folder_open</mat-icon>
									<span *ngIf="element.sampleStatus === 'INVALID'" class="material-symbols-outlined">folder_off</span>
								</button>
							</td>
						</ng-container>
	
						<!-- Sample Status Column -->
						<ng-container matColumnDef="sampleStatus">
							<th mat-header-cell *matHeaderCellDef mat-sort-header> Status der Probe </th>
							<td mat-cell *matCellDef="let element"> 
								<span  class="flex items-center">
									<span [ngClass]="{
										'reported': element.sampleStatus === 'REPORTED'
									}">{{element.sampleStatus | sampleStatus}} 
									</span>
									<span *ngIf="compareDates(element.feedbackDate, element.assessment?.timestamp)" class="ml-2" matTooltip="Achtung! Diese Bewertung wurde am {{element.assessment.timestamp | date}} nach ihrer Rückmeldung vom {{element.feedbackDate | date}} verändert.">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
											<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
											<path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z"/>
										</svg>
									</span>
								</span>
							</td>
						</ng-container>

						<!-- Client Column -->
						<ng-container matColumnDef="clientUid">
							<th mat-header-cell *matHeaderCellDef> Klient*in </th>
							<td mat-cell *matCellDef="let element">
								<mat-spinner *ngIf="isLoadingClientUid[element.id]" [diameter]="25"></mat-spinner>
								<span *ngIf="!isLoadingClientUid[element.id]">{{element.clientUid}}
									<app-copy-to-clipboard-button [textToCopy]="element.clientUid"></app-copy-to-clipboard-button>
								</span>
							</td>
						</ng-container>

						<!-- Sample Number Column -->
						<ng-container matColumnDef="sampleNumber">
							<th mat-header-cell *matHeaderCellDef> Probencode </th>
							<td mat-cell *matCellDef="let element"> 
								{{element.sampleNumber}} 
								<app-copy-to-clipboard-button [textToCopy]="element.sampleNumber"></app-copy-to-clipboard-button>
							</td>
						</ng-container>
	
						<!-- Sample Name Column -->
						<ng-container matColumnDef="name">
							<th mat-header-cell *matHeaderCellDef> Erworben als </th>
							<td mat-cell *matCellDef="let element"> {{element.name}} </td>
						</ng-container>
	
						<!-- Submission Date Column -->
						<ng-container matColumnDef="submissionDate">
							<th mat-header-cell *matHeaderCellDef mat-sort-header> Datum der Abgabe </th>
							<td mat-cell *matCellDef="let element"> {{element.submissionDate | date: 'dd.MM.YYYY'}} </td>
						</ng-container>

						<!-- Alert Column -->
						<ng-container matColumnDef="riskAssessment">
							<th mat-header-cell *matHeaderCellDef>  </th>
							<td mat-cell *matCellDef="let element">
								<span *ngIf="element.assessment?.misdeclared" class="bg-dc-blue-400 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded">falsch deklariert</span>
								<span *ngIf="element.assessment?.highlyDosed" class="bg-dc-blue-400 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded">hochdosiert</span>
								<span *ngIf="element.assessment?.contaminated" class="bg-dc-blue-400 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded">verunreinigt</span>
							</td>
						</ng-container>

						<!-- Alert 2 Column -->
						<ng-container matColumnDef="riskAssessment2">
							<th mat-header-cell *matHeaderCellDef>Risikobewertung</th>
							<td mat-cell *matCellDef="let element">
								<span [ngClass]="'circle circle-' + (getAlarmLevel(element.assessment) | lowercase)"></span>
								{{getAlarmLevel(element.assessment) | alert}}
							</td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
						<tr mat-row *matRowDef="let row; columns: displayedColumns;" class="hover:!bg-[#F1F1F1]"></tr>

						<!-- Row shown when there is no matching data. -->
						<tr class="mat-row" *matNoDataRow>
							<td class="mat-cell text-center p-6" colspan="6">Es konnten keine Proben gefunden werden.</td>
						</tr>
	
					</table>
	
				</mat-card-content>

				<mat-paginator class="mat-elevation-z0 mt-4" 
							[length]="totalElements"
							[pageSize]="pageSize"
							[hidePageSize]="true"
							(page)="pageChanged($event)" 
							showFirstLastButtons>
				</mat-paginator>

			</mat-card>
            <!--
			<mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
            -->
		</div>

	</div>

</div>