import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CoreModule } from '@core/core.module';
import { MaterialModule } from '@core/layout/material/material.module';

import { ToolbarComponent } from '@core/layout/toolbar/toolbar.component';
import { HomeComponent } from './home/home.component';
import { SittingComponent } from './sitting/sitting.component';
import { ConsultationComponent } from './sitting/consultation/consultation.component';



import { ClientSearchformComponent } from '@shared/components/client-search-form/client-search-form.component';
import { SampleComponent } from './sitting/consultation/sample/sample.component';
import { SampleDetailDialogComponent } from './sitting/consultation/sample/sample-detail-dialog/sample-detail-dialog.component';
import { YearMonthFormatDirective } from '@shared/directives/date-formats/year-month-format.directive';
import { ResultsComponent } from './results/results.component';
import { LabComponent } from './lab/lab.component';
import { BreadcrumbDirective } from '@shared/directives/breadcrumb/breadcrumb.directive';
import { TextblockComponent } from './textblock/textblock.component';
import { TextblockDialogComponent } from './textblock/textblock-dialog/textblock-dialog.component';
import { ClientUidGeneratorComponent } from '@shared/components/client-uid-generator/client-uid-generator.component';
import { SampleNumberSearchFormComponent } from '@shared/components/sample-number-search-form/sample-number-search-form.component';
import { ClientUidDialogComponent } from './results/client-uid-dialog/client-uid-dialog.component';
import { UserComponent } from './user/user.component';
import { ClientComponent } from './client/client.component';
import { ResultDialogComponent } from './results/result-dialog/result-dialog.component';
import { LabDialogComponent } from './lab/lab-dialog/lab-dialog.component';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { SalutationPipe } from '@core/pipes/salutation.pipe';
import { SampleStatusPipe } from '@core/pipes/sample-status.pipe';
import { SittingStatusPipe } from '@core/pipes/sitting-status.pipe';
import { ConsultationStatusPipe } from '@core/pipes/consultation-status.pipe';
import { GenderPipe } from '@core/pipes/gender.pipe';
import { NationalityPipe } from '@core/pipes/nationality.pipe';
import { CopyToClipboardButtonComponent } from '@shared/components/copy-to-clipboard-button/copy-to-clipboard-button.component';
import { UserDialogComponent } from './user/user-dialog/user-dialog.component';
import { AppInfoDialogComponent } from '@shared/components/app-info-dialog/app-info-dialog.component';
import { MeDialogComponent } from '@shared/components/me-dialog/me-dialog.component';
import { ExportDialogComponent } from '@shared/components/export-dialog/export-dialog.component';
import { RolePipe } from '@core/pipes/role.pipe';
import { UserStatusPipe } from '@core/pipes/user-status.pipe';
import { OrderMultipleDirective } from '@shared/directives/order-multiple.directive';
import { TextblockSheetComponent } from './lab/lab-dialog/textblock-sheet/textblock-sheet.component';
import { OrganizationPipe } from '@core/pipes/organization.pipe';
import { StartConsultationDialogComponent } from './sitting/consultation/start-consultation-dialog/start-consultation-dialog.component';
import { ClientAddEditComponent } from '@shared/components/client-add-edit/client-add-edit.component';
import { AutocompleteNationalitiesComponent } from '@shared/components/client-add-edit/autocomplete-nationalities/autocomplete-nationalities.component';
import { ClientSearchTabComponent } from './sitting/consultation/start-consultation-dialog/client-search-tab/client-search-tab.component';
import { ClientDialogComponent } from './client/client-dialog/client-dialog.component';
import { NumberFormatPipe } from '@core/pipes/number-format.pipe';
import { EditorModule } from '@tinymce/tinymce-angular';
import { TruncatePipe } from '@core/pipes/truncate.pipe';
import { SanitizeHtmlPipe } from '@core/pipes/sanitize-html.pipe';
import { AlertPipe } from '@core/pipes/alert.pipe';
import { SampleStatusStepperComponent } from './lab/lab-dialog/sample-status-stepper/sample-status-stepper.component';


@NgModule({
	declarations: [
		HomeComponent,
		LabComponent,
		ResultsComponent,
		TextblockComponent,
		TextblockDialogComponent,
		ToolbarComponent,
		UserComponent,
		ClientComponent,
		StartConsultationDialogComponent,
		ClientSearchformComponent,
		ClientAddEditComponent,
		AutocompleteNationalitiesComponent,
		ClientSearchTabComponent,
		ClientUidGeneratorComponent,
		SampleNumberSearchFormComponent,
		ClientUidDialogComponent,
		ConsultationComponent,
		SampleComponent,
		SampleDetailDialogComponent,
		SittingComponent,
		ResultDialogComponent,
		LabDialogComponent,
		ConfirmationDialogComponent,
		CopyToClipboardButtonComponent,
		SalutationPipe,
		SampleStatusPipe,
		SittingStatusPipe,
		ConsultationStatusPipe,
		GenderPipe,
		NationalityPipe,
		BreadcrumbDirective,
		YearMonthFormatDirective,
  		UserDialogComponent,
		AppInfoDialogComponent,
		MeDialogComponent,
		ExportDialogComponent,
		RolePipe,
		UserStatusPipe,
		OrderMultipleDirective,
  		TextblockSheetComponent,
		OrganizationPipe,
  		ClientDialogComponent,
		NumberFormatPipe,
		TruncatePipe,
		SanitizeHtmlPipe,
		AlertPipe,
  SampleStatusStepperComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		CoreModule,
		RouterModule,
		EditorModule
	],
	providers: [
		CurrencyPipe,
		DatePipe,
		DecimalPipe,
		NumberFormatPipe,
		{ provide: LOCALE_ID, useValue: 'de-DE' },
	]
})
export class AdminModule {
	constructor() { registerLocaleData(localeDe) }
 }
