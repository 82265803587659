import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ApiService } from '@core/services/api.service';
import { Result, Substance } from '@shared/models/result.interface';

@Component({
	selector: 'app-result-dialog',
	templateUrl: './result-dialog.component.html',
	styleUrls: ['./result-dialog.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ResultDialogComponent implements OnInit {

	// Holds the sanitized evaluation text
	sanitizedEvaluation: SafeHtml;

	//
	displayedColumnsSubstances: string[] = ['number', 'name', 'amount'];
	dataSourceSubstances: MatTableDataSource<Substance[]>

	// 
	displayedColumnsResult: string[] = ['weight', 'height', 'width', 'depth'];
	dataSourceResult: MatTableDataSource<Result>

	constructor(
		private apiService: ApiService,
		private snackBar: MatSnackBar,
		private dialogRef: MatDialogRef<ResultDialogComponent>,
		private sanitizer: DomSanitizer,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit(): void {
		// Sanitize the evaluation text and replace line breaks with <br> tags
		this.sanitizedEvaluation = this.sanitizer.bypassSecurityTrustHtml(this.data.result.evaluation.replace(/\n/g, '<br>'));
		//
		this.dataSourceResult = new MatTableDataSource([this.data.result]);
		this.dataSourceSubstances = new MatTableDataSource(this.data.result.substances);

		console.log(this.dataSourceResult.data)
	}

	/**
	 * Returns the alarm level based on "isAlert" and "isRedAlert".
	 * @since 1.0.0
	 * @param {Object} result - The result object containing the isAlert and isRedAlert properties.
	 * @returns {string|null} - The alarm level as a string or null if the result is invalid.
	 */
	getAlarmLevel(result: { isAlert: boolean | null, isRedAlert: boolean | null } | null): string | null {
		if (!result || result.isAlert === null || result.isRedAlert === null) { return null; }
		if (result.isAlert && result.isRedAlert) { return 'ALARM!'; }
		if (result.isAlert) { return 'Warnung'; }
		return 'Unauffällig';
	}

	/**
	 * Handles feedback given by updating sample status and showing a snackbar.
	 * @since 1.0.0
	 * @returns void
	 */
	feedbackGiven(): void {
		this.apiService.updateSampleStatus(this.data.id, 'REPORTED').subscribe((data: any) => {
			this.snackBar.open(
				`Die Probe ${data.sampleNumber} wurde als "zurückgemeldet" gekennzeichnet.`, 
				'Schließen', 
				{panelClass: 'success-snack', duration: 2000} 
			)
			this.dialogRef.close({data: data})
		});
	}
}
