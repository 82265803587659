<mat-toolbar color="primary">
    <img src="assets/drgchckn_small_white.png" width="180" height="auto" class="ml-1 mr-1 hover:cursor-pointer" alt="drugchecking Berlin" [routerLink]="routeToRedirect" />
    <!-- Spacer -->
    <span class="w-[1rem]"></span>
    <!-- Main menu -->
    <nav role="navigation" class="menu">

        <a  *ngIf="['ADMIN', 'CONSULTANT'].includes(currentUser.role)" class="menu-item" mat-button [routerLink]="'/sitting'" routerLinkActive="active" 
            matBadge="&#8288;" 
            matBadgePosition="after"
            matBadgeColor="warn"
            [matBadgeHidden]="!hasOpenSitting"> Sprechstunde 
        </a>

        <a *ngIf="['ADMIN', 'CONSULTANT'].includes(currentUser.role)" 
            class="menu-item" 
            mat-button [routerLink]="'/results'" 
            routerLinkActive="active"> Rückmeldungen 
        </a>

        <a *ngIf="['ADMIN', 'PHARMACIST', 'LABORATORY'].includes(currentUser.role)" 
            class="menu-item" 
            mat-button 
            [routerLink]="'/laboratory'" 
            routerLinkActive="active"> Labor 
        </a>

        <a *ngIf="['ADMIN', 'CONSULTANT'].includes(currentUser.role)" 
            class="menu-item" 
            mat-button 
            [routerLink]="'/clients'" 
            routerLinkActive="active"> Klienten/Klientinnen
        </a>

        <a *ngIf="['ADMIN', 'PHARMACIST'].includes(currentUser.role)" 
            class="menu-item" 
            mat-button 
            [routerLink]="'/textblock'" 
            routerLinkActive="active" > Textbausteine 
        </a>

    </nav>
    <!-- Spacer -->
    <span class="flex-auto"></span>
    <!-- Me -->
    <button mat-icon-button [matMenuTriggerFor]="meMenu" aria-label="Account">
        <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #meMenu="matMenu">
        <button *ngIf="['ADMIN'].includes(currentUser.role)" mat-menu-item [routerLink]="'/users'">
            <mat-icon>manage_accounts</mat-icon> Benutzer*innen
        </button>
        <button *ngIf="['ADMIN'].includes(currentUser.role)" mat-menu-item (click)="openExportDialog()">
            <mat-icon class="rotate-180">upload_file</mat-icon> Daten Export
        </button>
        <button mat-menu-item (click)="openMeDialog()">
            <mat-icon>person</mat-icon> Mein Konto
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="openAppInfoDialog()">
            <mat-icon>help</mat-icon> Über diese App
        </button>
        <button mat-menu-item (click)="logout()">
            <mat-icon>logout</mat-icon> Abmelden
        </button>
    </mat-menu>
</mat-toolbar>