<div class="bg-[#F3F3F3] p-8">

    <h2 class="!inline-flex !w-full !m-0 !p-0" mat-dialog-title>Laborergebnis: {{getAlarmLevel(data.result)}}<br>
        <span class="flex-1"></span>
        <span>{{data.sampleNumber}}</span>
    </h2>
    <small class="text-sm text-[#777] flex justify-start mb-4 -mt-[18px]">
        <span class="text-sm material-symbols-outlined mr-1">event_available</span>{{data.result.timestamp | date: 'dd.MM.YYYY'}}
        <span class="text-sm material-symbols-outlined ml-2 mr-1">schedule</span>{{data.result.timestamp | date: 'HH:mm:ss'}} Uhr
    </small>

    <mat-dialog-content class="bg-[#FFFFFF] !max-h-[40vh] !mb-4 !text-[#000000]">
        <span [innerHTML]="sanitizedEvaluation"></span>
    </mat-dialog-content>

    <table mat-table *ngIf="dataSourceResult.data.length" [dataSource]="dataSourceResult" class="mat-elevation-z0 mb-4">
        <!-- Weight Column -->
        <ng-container matColumnDef="weight">
            <th mat-header-cell *matHeaderCellDef> Gewicht </th>
            <td mat-cell *matCellDef="let element"> 
                <span *ngIf="element.weight">{{element.weight | numberFormat}} mg</span>
                <span *ngIf="!element.weight">k.A.</span>
            </td>
        </ng-container>
        <!-- Height Column -->
        <ng-container matColumnDef="height">
            <th mat-header-cell *matHeaderCellDef> Höhe </th>
            <td mat-cell *matCellDef="let element"> 
                <span *ngIf="element.height">{{element.height | numberFormat}} mm</span>
                <span *ngIf="!element.height">k.A.</span>
            </td>
        </ng-container>
        <!-- Width Column -->
        <ng-container matColumnDef="width">
            <th mat-header-cell *matHeaderCellDef> Breite </th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.width">{{element.width | numberFormat}} mm</span>
                <span *ngIf="!element.width">k.A.</span>
            </td>
        </ng-container>
        <!-- Depth Column -->
        <ng-container matColumnDef="depth">
            <th mat-header-cell *matHeaderCellDef> Tiefe </th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.depth">{{element.depth | numberFormat}} mm</span>
                <span *ngIf="!element.depth">k.A.</span>
            </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumnsResult"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsResult;"></tr>
    </table>

    <table mat-table *ngIf="dataSourceSubstances.data.length" [dataSource]="dataSourceSubstances" class="mat-elevation-z0">
        <!-- Number Column -->
        <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef> Substanz </th>
            <td mat-cell *matCellDef="let element; let index = index"> {{index + 1}} </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
        <!-- Amount Column -->
        <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef> Menge </th>
            <td mat-cell *matCellDef="let element"> {{element.amount | numberFormat}} {{element.unit}} </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumnsSubstances"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsSubstances;"></tr>
    </table>

</div>

<mat-dialog-actions align="start">
    <button mat-button mat-dialog-close>Abbrechen</button>
    <span class="flex-1"></span>
    <button mat-flat-button color="primary" cdkFocusInitial (click)="feedbackGiven()" [disabled]="data.sampleStatus === 'REPORTED'">
        <mat-icon>check</mat-icon> Rückmeldung
    </button>
</mat-dialog-actions>