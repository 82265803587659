import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '@core/services/api.service';
import { MatTableDataSource } from '@angular/material/table';
import { FeedbackDialogComponent } from '../feedback-dialog/feedback-dialog.component';
import { forkJoin } from 'rxjs';
import { Sample } from '@shared/models/sample.interface';
import { MatSort } from '@angular/material/sort';


@Component({
	selector: 'app-feedback-list',
	templateUrl: './feedback-list.component.html',
	styleUrls: ['./feedback-list.component.scss']
})
export class FeedbackListComponent implements OnInit {

	/** Total number of elements in the data source. */
	totalElements: number = 0;

	/** Number of items to display on a page. */
	pageSize: number = 20;

	displayedColumns: string[] = [
		'result',
		'clientUid',
		'sampleNumber',
		'name',
		'submissionDate',
		'riskAssessment2',
		'riskAssessment',
	];

	/** Initialize data source with an empty array. */
	dataSource: MatTableDataSource<Sample> = new MatTableDataSource<Sample>();

	isLoading: boolean = false;
	isLoadingClientUid: any = {};

	@ViewChild(MatSort) sort: MatSort;

	constructor(
		private dialog: MatDialog,
		private apiService: ApiService
	) { }

	ngOnInit(): void {
		this.getAssessedSamples({ sampleStatus: 'ASSESSED' });
	}

	getAssessedSamples(params: any): void {
		this.isLoading = true;

		this.apiService.getSamples(params).subscribe({
			next: (data: any) => {

				this.totalElements = data.totalElements;

				const assessedSamples: Sample[] = data.content;
				// Create a new MatTableDataSource from the samples
				this.dataSource = new MatTableDataSource<Sample>(assessedSamples);
				this.dataSource.sort = this.sort;
				
				const consultationRequests = assessedSamples.map((assessedSample: Sample) => {
					this.isLoadingClientUid[assessedSample.id] = true;
					return this.apiService.getConsultation(assessedSample.consultationId);
				});

				forkJoin(consultationRequests).subscribe({
					next: (consultationResponses: any) => {
						for (let i = 0; i < consultationResponses.length; i++) {
							const clientUid = consultationResponses[i]?.client?.clientUid;

							// Access the underlying data array in MatTableDataSource
							this.dataSource.data[i].clientUid = clientUid;

							// Mark loading as complete for that sample's clientUid
							this.isLoadingClientUid[assessedSamples[i].id] = false;
						}

						// Trigger change detection by reassigning the data array
						this.dataSource.data = [...this.dataSource.data];
						this.isLoading = false;
					},
					error: (error) => {
						console.error(error);
						this.isLoading = false;
					}
				});
			},
			error: () => {
				this.isLoading = false;
			}
		});
	}

	sortSamples(samples: Sample[]): any {
		if (samples.length <= 1) return samples
		return samples.sort((a, b) => {
			const dateA = Date.parse(a.submissionDate);
			const dateB = Date.parse(b.submissionDate);

			if (dateA < dateB) return -1;
			if (dateA > dateB) return 1;

			const statusOrder = ['ASSESSED'];

			const statusA = statusOrder.indexOf(a.sampleStatus);
			const statusB = statusOrder.indexOf(b.sampleStatus);

			return statusA - statusB;
		});
	}


	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
		if (this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	/**
	 * Event handler for when the page in the paginator changes.
	 * @since 1.0.0
	 * @param {any} event - The page change event.
	 * @return void
	 */
	pageChanged(event: any): void {
		this.pageSize = event.pageSize;
		this.getAssessedSamples({
			sampleSatus: 'ASSESSED',
			size: event.pageSize,
			page: event.pageIndex
		});
	}

	/**
	 * Returns the alarm level for the given result.
	 * @since 1.0.0
	 * @param result The result object.
	 * @returns The alarm level as a string.
	 */
	getAlarmLevel(result: { isAlert: boolean | null, isRedAlert: boolean | null } | null): string {
		if (!result || result.isAlert === null || result.isRedAlert === null) { return ''; }
		if (result.isAlert && result.isRedAlert) { return 'REDALERT'; }
		if (result.isAlert) { return 'ALERT'; }
		return 'NONE';
	}


	compareDates(feedbackDate: string | null, timestamp: string | null): boolean {

		if (!feedbackDate || !timestamp) {
			return false;
		}

		const feedback = new Date(feedbackDate);
		const assessment = new Date(timestamp);

		return feedback < assessment;
	}

	/**
	 * Opens the result dialog and updates the data source with the modified data.
	 * @since 1.0.0
	 * @param {Sample} sample - The sample object to be displayed in the result dialog.
	 * @returns void
	 */
	openResultDialog(sample: Sample): void {
		// Open result dialog
		let dialogRef = this.dialog.open(FeedbackDialogComponent, {
			data: sample,
			width: '45vw',
			maxHeight: '98vh',
			autoFocus: false
		});

		dialogRef.afterClosed().subscribe(res => {
			if (res?.data) {
				const updatedData = this.dataSource.data.map((d: any) => d.id === res.data.id ? res.data : d);
				this.dataSource.data = updatedData;
			}
		});
	}

}
